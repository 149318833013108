import React from 'react';
import ReactDOM from 'react-dom/client';
//import { BrowserRouter as Router } from 'react-router-dom';
import './index.css'; // Your CSS imports
import App from './App'; // Import the main App component
import reportWebVitals from './reportWebVitals'; // For measuring performance

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
    //<Router> {/* Wrap App with BrowserRouter */}
      <App />
    //</Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
