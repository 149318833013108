import React from 'react';
import './PrivacyPolicy.css'; // Import corresponding CSS for styling

function PrivacyPolicy() {
  return (
    <div className="privacy-container">
      <h2>Privacy Policy for Neon Desire</h2>
      <p>Effective Date: 10/31/2024</p>
      <p>Neon Desire ("we," "us," "our") is committed to protecting the privacy of our users ("you," "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services. By accessing or using Neon Desire, you agree to the terms outlined in this policy.</p>

      <h3>1. Information We Collect</h3>
      <ul>
        <li><strong>Personal Information:</strong> We may collect personal information, such as your name, email address, username, and profile picture, when you create an account or communicate with us.</li>
        <li><strong>Content and Media:</strong> Any content, media files, or posts that you upload to Neon Desire.</li>
        <li><strong>Technical Data:</strong> Includes your IP address, browser type, operating system, device information, and access times.</li>
        <li><strong>Usage Data:</strong> Information about how you use the platform, including interactions with content, other users, and features.</li>
      </ul>

      <h3>2. How We Use Your Information</h3>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide and maintain our services, including user authentication and personalized content delivery.</li>
        <li>To improve user experience by analyzing how users interact with the platform and implementing enhancements.</li>
        <li>To communicate with you about updates, new features, or support inquiries.</li>
        <li>For security and fraud prevention to detect and mitigate malicious or unauthorized activity.</li>
        <li>To comply with legal obligations and enforce our Terms of Service and Prohibited Content Policy.</li>
        <li>For marketing and advertising purposes, including promoting user-generated content on various channels.</li>
      </ul>

      <h3>3. Disclosure of Your Information</h3>
      <p>We may share your information in the following situations:</p>
      <ul>
        <li><strong>With your consent:</strong> We may share your information when you have provided explicit consent.</li>
        <li><strong>With service providers:</strong> We engage third-party service providers to help us operate and improve our services (e.g., cloud storage, analytics).</li>
        <li><strong>For legal reasons:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        <li><strong>Business transfers:</strong> If Neon Desire is involved in a merger, acquisition, or sale of assets, your information may be transferred as part of that transaction.</li>
      </ul>

      <h3>4. Cookies and Tracking Technologies</h3>
      <p>We use cookies and similar tracking technologies to collect and store information about your interactions with Neon Desire. You can control cookie settings through your browser preferences, but this may affect certain functionalities of the platform and break features.</p>

      <h3>5. Data Security</h3>
      <p>We use industry-standard security measures to protect your information. However, no system is completely secure, and we cannot guarantee the absolute security of your data. Users are encouraged to take precautions to protect their account information and personal data.</p>

      <h3>6. User-Generated Content</h3>
      <p>Any content you upload to Neon Desire may be visible to other users and can be used by Neon Desire as described in the Terms of Service. You are responsible for ensuring that your content does not violate our Prohibited Content Policy or any applicable laws.</p>

      <h3>7. Your Rights</h3>
      <p>Depending on your jurisdiction, you may have certain rights related to your personal data, including:</p>
      <ul>
        <li><strong>Access:</strong> Request access to the personal information we hold about you.</li>
        <li><strong>Correction:</strong> Request corrections to any inaccurate or incomplete personal information.</li>
        <li><strong>Deletion:</strong> Request the deletion of your personal information, subject to platform policies and legal obligations.</li>
        <li><strong>Data portability:</strong> Request a copy of your personal data in a commonly used format.</li>
      </ul>

      <h3>8. Data Retention</h3>
      <p>We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Upon termination or deactivation of your account, we may retain data for a reasonable period to comply with legal obligations or resolve disputes.</p>

      <h3>9. Third-Party Links</h3>
      <p>Neon Desire may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of any third-party websites you visit.</p>

      <h3>10. Children’s Privacy</h3>
      <p>Neon Desire is not intended for individuals under the age of 18. We do not knowingly collect or solicit personal information from anyone under 18 years of age. If we discover that we have collected personal information from a user under the age of 18, we will delete that information as quickly as possible. If you believe we might have any information from or about a minor, please contact us at support@neondesire.com.</p>

      <h3>11. Governing Law</h3>
      <p>This Privacy Policy shall be governed by and construed in accordance with the laws of the State of Texas, United States, without regard to its conflict of laws principles. Users agree that any disputes arising from this policy or use of Neon Desire will be resolved in the courts of Texas.</p>

      <h3>12. Changes to This Privacy Policy</h3>
      <p>We may update this Privacy Policy from time to time. Any changes will be communicated by updating the effective date at the top of this policy. Your continued use of Neon Desire after changes to this policy constitutes your acceptance of the revised terms.</p>

      <h3>13. Contact Us</h3>
      <p>If you have any questions, concerns, or complaints regarding this Privacy Policy or how we handle your data, please contact us at <a href="mailto:support@neondesire.com">support@neondesire.com</a>.</p>
    </div>
  );
}

export default PrivacyPolicy;
