// src/components/PostPage.js
import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import LikeCommentShareButton from './LikeCommentShareButton';
import VideoPlayer from './VideoPlayer';
import { api, publicApi } from '../services/apiService';
import './PostPage.css';
import SEO from './SEO';
import seoConfig from './seoConfig';

const PostPage = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const extid = 'YOUR_EXTID_VALUE'; // Replace with your value
  const adContainerRef = useRef(null);

  // Parallelized API Calls with improved error handling and state management
  useEffect(() => {
    let isMounted = true; // To prevent state updates if component unmounts

    const fetchData = async () => {
      try {
        const [postResponse, commentsResponse] = await Promise.all([
          publicApi.get(`/posts/${id}`),
          publicApi.get(`/posts/${id}/comments`),
        ]);

        if (postResponse.status !== 200) throw new Error('Post not found');
        if (commentsResponse.status !== 200) throw new Error('Comments not found');

        const postData = postResponse.data;
        postData.description = DOMPurify.sanitize(postData.description);

        if (isMounted) {
          setPost(postData);
          setComments(commentsResponse.data);
        }

        if (postData.username) {
          try {
            const userResponse = await api.get(`/users/${postData.username}`);
            if (userResponse.status === 200 && isMounted) {
              setProfileImage(userResponse.data.profileImage);
            }
          } catch (userError) {
            console.error('Error fetching user data:', userError);
            // Optionally set a default profile image or handle the error
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [id]);

  // Asynchronously load ads with optimized script loading
  useEffect(() => {
    const loadAds = () => {
      if (!adContainerRef.current) return;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//cdn.tsyndicate.com/sdk/v1/bi.js';
      script.setAttribute('data-ts-spot', '243e1d1d1ab846cbba3e07378d31f65b');
      script.setAttribute('data-ts-width', '300');
      script.setAttribute('data-ts-height', '250');
      script.setAttribute('data-ts-extid', extid);
      script.async = true;
      script.defer = true;

      adContainerRef.current.appendChild(script);

      return () => {
        if (adContainerRef.current && adContainerRef.current.contains(script)) {
          adContainerRef.current.removeChild(script);
        }
      };
    };

    const cleanup = loadAds();

    return cleanup;
  }, [extid]);

  // Memoized comment submit handler to prevent re-creation on each render
  const handleCommentSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const sanitizedComment = DOMPurify.sanitize(newComment.trim());

      if (!sanitizedComment) return;

      try {
        const response = await api.post(`/posts/${id}/comments`, {
          text: sanitizedComment,
        });

        if (response.status === 201) {
          setComments((prevComments) => [...prevComments, response.data]);
          setNewComment('');
        } else {
          throw new Error('Failed to submit comment');
        }
      } catch (submitError) {
        console.error('Error submitting comment:', submitError);
        setError('Failed to submit comment. Please try again.');
      }
    },
    [id, newComment]
  );

  // Memoized SEO data to avoid recalculations unless post changes
  const seoData = useMemo(() => {
    return post
      ? seoConfig.post(post.username, post.description)
      : {
          title: 'Neon Desire - View Post',
          description: 'Explore exclusive content on Neon Desire.',
        };
  }, [post]);

  // Memoized media rendering to prevent unnecessary re-renders
  const renderedMedia = useMemo(() => {
    if (!post || !post.mediaFiles || !post.mediaTypes) return null;

    return post.mediaFiles.map((mediaUrl, index) => {
      const mediaType = post.mediaTypes[index];
      if (mediaType === 'image') {
        return (
          <img
            key={index}
            src={mediaUrl}
            alt="Post Media"
            className="post-image"
            loading="lazy"
          />
        );
      } else if (mediaType === 'video') {
        return (
          <VideoPlayer
            key={index}
            poster={post.poster}
            qualityOptions={[{ label: 'Default', src: mediaUrl }]}
          />
        );
      }
      return null;
    });
  }, [post]);

  // Memoized shorts rendering
  const renderedShorts = useMemo(() => {
    if (!post || !post.mediaFiles || !post.mediaTypes) return null;

    return post.mediaFiles
      .map((mediaUrl, index) => {
        if (post.mediaTypes[index] === 'short') {
          return (
            <div key={index} className="short-content">
              <VideoPlayer
                poster={post.poster}
                qualityOptions={[{ label: 'Default', src: mediaUrl }]}
              />
            </div>
          );
        }
        return null;
      })
      .filter(Boolean);
  }, [post]);

  return (
    <div className="post-page-container">
      <SEO title={seoData.title} description={seoData.description} />

      {loading && !error && <div className="post-loading">Loading post...</div>}
      {error && <div className="post-error">Error: {error}</div>}

      {!loading && !error && post && (
        <div className="post-content">
          <div className="post-header">
            {profileImage && (
              <img
                src={profileImage}
                alt={`${post.username}'s profile`}
                className="post-picture"
                loading="lazy"
              />
            )}
            <h2 className="post-username">
              <Link to={`/profile/${post.username}`}>{post.username}</Link>
            </h2>
          </div>

          <p className="post-body">{post.description}</p>

          <div className="post-media-container">{renderedMedia}</div>

          {post.mediaTypes.includes('short') && (
            <div className="post-shorts-container">{renderedShorts}</div>
          )}

          <LikeCommentShareButton postId={id} />

          <h2 className="comments-header">Comments</h2>
          {comments.length > 0 ? (
            <ul className="comments-list">
              {comments.map((comment) => (
                <li key={comment._id} className="comment-item">
                  <strong>{comment.username}</strong>: {comment.text}
                </li>
              ))}
            </ul>
          ) : (
            <p className="no-comments">No comments yet.</p>
          )}

          <form className="comment-form" onSubmit={handleCommentSubmit}>
            <textarea
              className="comment-input"
              placeholder="Leave a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              required
            />
            <button type="submit" className="comment-submit-btn">
              Submit
            </button>
          </form>
        </div>
      )}

      <div
        ref={adContainerRef}
        className="ad-section"
        style={{ marginTop: '50px', textAlign: 'center' }}
      >
        <iframe
          width="300"
          height="250"
          frameBorder="0"
          scrolling="no"
          src={`//tsyndicate.com/iframes2/243e1d1d1ab846cbba3e07378d31f65b.html?extid=${extid}`}
          title="Advertisement"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default PostPage;
