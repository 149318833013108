import React, { useEffect, useState } from 'react';
import './Post.css';
import LikeCommentShareButton from './LikeCommentShareButton';
import { Link } from 'react-router-dom';
import { publicApi } from '../services/apiService';

const Post = ({ post }) => {
  const [profileImage, setProfileImage] = useState(null);
  const [activeVideoIndices, setActiveVideoIndices] = useState([]);

  const { _id, username, description, mediaTypes, mediaFiles, thumbnails, createdAt } = post || {};

  useEffect(() => {
    if (username) {
      const fetchProfileImage = async () => {
        try {
          const response = await publicApi.get(`/users/${username}`);
          if (response.status === 200) {
            setProfileImage(response.data.profileImage);
          }
        } catch (error) {
          console.error('Error fetching profile image:', error);
        }
      };
      fetchProfileImage();
    }
  }, [username]);

  if (!post) {
    return <div className="post-container">Post not found</div>;
  }

  const handleThumbnailClick = (index) => {
    setActiveVideoIndices((prevIndices) => [...prevIndices, index]);
  };

  const renderMedia = () => {
    if (
      !mediaTypes ||
      !mediaFiles ||
      mediaTypes.length === 0 ||
      mediaFiles.length === 0
    ) {
      return null;
    }

    return (
      <div className="media-container">
        {mediaFiles.map((mediaFile, index) => {
          const type = mediaTypes[index];
          const thumbnailUrl = thumbnails && thumbnails[index] ? thumbnails[index] : null;
          const isActive = activeVideoIndices.includes(index);

          if (type === 'image') {
            return (
              <img
                key={index}
                src={mediaFile}
                alt={`Media ${index + 1}`}
                className="post-image"
                loading="lazy"
              />
            );
          } else if (type === 'video' || type === 'short') {
            return (
              <div key={index} className="media-item">
                {isActive ? (
                  <video
                    src={mediaFile}
                    controls
                    autoPlay={type === 'short'}
                    loop={type === 'short'}
                    muted={type === 'short'}
                    className="post-video"
                  >
                    <source src={mediaFile} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <div
                    className="video-thumbnail-container"
                    onClick={() => handleThumbnailClick(index)}
                    role="button"
                    tabIndex={0}
                    aria-label={`Play video ${index + 1}`}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleThumbnailClick(index);
                      }
                    }}
                  >
                    {thumbnailUrl ? (
                      <img
                        src={thumbnailUrl}
                        alt={`Thumbnail ${index + 1}`}
                        className="video-thumbnail"
                        loading="lazy"
                      />
                    ) : (
                      // Use a video element to display the first frame of the video if no thumbnail
                      <video
                        src={mediaFile}
                        className="video-thumbnail"
                        preload="metadata"
                        muted
                        playsInline
                        onLoadedMetadata={(e) => {
                          e.target.currentTime = 0.1;
                          e.target.pause();
                        }}
                      />
                    )}
                    <div className="play-indicator" aria-hidden="true">
                      ▶
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    );
  };

  return (
    <div className="post-container">
      <div className="user-info">
        {profileImage ? (
          <img
            src={profileImage}
            alt={`${username}'s profile`}
            className="profile-image"
          />
        ) : (
          <div className="profile-placeholder">No Image</div>
        )}
        <h2 className="username">
          <Link to={`/profile/${username}`}>{username}</Link>
        </h2>
      </div>

      <p className="post-description">{description}</p>

      {renderMedia()}

      <p className="post-time">Posted on: {new Date(createdAt).toLocaleString()}</p>

      <LikeCommentShareButton postId={_id} />
      <Link to={`/post/${_id}`} className="view-post">
        View Post
      </Link>
    </div>
  );
};

export default Post;
