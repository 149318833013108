// src/components/LikeCommentShareButton.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { api, publicApi } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import './LikeCommentShareButton.css';

function LikeCommentShareButton({ postId, commentLabel, shareLabel }) {
  const [likeStatus, setLikeStatus] = useState({
    likeLabel: 'Like',
    likesCount: 0,
    userLiked: false,
  });
  const [commentCount, setCommentCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showShareLink, setShowShareLink] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLikeStatus = async () => {
      try {
        const response = await api.get(`/posts/${postId}/likes`);
        if (response.status === 200) {
          const data = response.data;
          setLikeStatus({
            likesCount: data.likesCount,
            userLiked: data.userLiked,
            likeLabel: data.userLiked ? 'Unlike' : 'Like',
          });
        } else {
          console.error('Failed to fetch like status');
        }
      } catch (error) {
        console.error('Error fetching like status:', error);
      }
    };

    const fetchCommentCount = async () => {
      try {
        const response = await publicApi.get(`/posts/${postId}/comments/count`);
        if (response.status === 200) {
          setCommentCount(response.data.count);
        } else {
          console.error('Failed to fetch comment count');
        }
      } catch (error) {
        console.error('Error fetching comment count:', error);
      }
    };

    const incrementViewCount = async () => {
      try {
        const response = await publicApi.post(`/posts/${postId}/view`);
        if (response.status === 200) {
          const data = response.data;
          setViewCount(data.viewCount);
        } else {
          console.error('Failed to increment view count');
        }
      } catch (error) {
        console.error('Error updating view count:', error);
      }
    };

    fetchLikeStatus();
    fetchCommentCount();
    incrementViewCount();
    setLoading(false);
  }, [postId]);

  const handleLike = async () => {
    const { userLiked } = likeStatus;
    const previousLikeStatus = { ...likeStatus };

    setLikeStatus((prevState) => ({
      userLiked: !prevState.userLiked,
      likesCount: prevState.userLiked
        ? prevState.likesCount - 1
        : prevState.likesCount + 1,
      likeLabel: !prevState.userLiked ? 'Unlike' : 'Like',
    }));

    const method = userLiked ? 'delete' : 'post';
    const endpoint = `/posts/${postId}/like`;

    try {
      const response = await api({ method, url: endpoint });

      if (response.status !== 200 && response.status !== 201) {
        setLikeStatus(previousLikeStatus);
        console.error('Failed to update like status');
      }
    } catch (error) {
      setLikeStatus(previousLikeStatus);
      console.error('Error updating like status:', error);
    }
  };

  const handleCommentClick = () => {
    navigate(`/post/${postId}`);
  };

  const handleShareClick = () => {
    setShowShareLink((prev) => !prev);
    // Close options popup if open
    if (showOptions) setShowOptions(false);
  };

  const handleOptionsClick = () => {
    setShowOptions((prev) => !prev);
    // Close share popup if open
    if (showShareLink) setShowShareLink(false);
  };

  const handleReport = async () => {
    try {
      const response = await api.post('/report', {
        postId,
        reason: 'Inappropriate content', 
      });
      if (response.status === 201) {
        alert('Report submitted successfully.');
        setShowOptions(false);
      } else {
        console.error('Failed to submit report.');
      }
    } catch (error) {
      console.error('Error reporting post:', error);
    }
  };

  const shareLink = `${window.location.origin}/post/${postId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shareLink).then(
      () => alert('Link copied to clipboard!'),
      (err) => console.error('Failed to copy: ', err)
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="LikeCommentShareButton">
      <div className="button-container">
        <button className="view-count" aria-label="Views">
          <i className="fas fa-eye"></i> {viewCount} Views
        </button>
        <div className="buttons-wrapper">
          <button onClick={handleLike} className="like-button button" aria-label="Like">
            <i className="fas fa-thumbs-up"></i> {likeStatus.likeLabel} ({likeStatus.likesCount})
          </button>
          <button
            onClick={handleCommentClick}
            className="comment-button button"
            aria-label="Comment"
          >
            <i className="fas fa-comment"></i> {commentLabel} ({commentCount})
          </button>
          <button
            onClick={handleShareClick}
            className={`share-button button ${showShareLink ? 'active' : ''}`}
            aria-label="Share"
          >
            <i className="fas fa-share"></i> {shareLabel}
          </button>
          {showShareLink && (
            <div className="share-popup">
              <input
                type="text"
                value={shareLink}
                readOnly
                onClick={(e) => e.target.select()}
              />
              <button onClick={copyToClipboard}>Copy Link</button>
            </div>
          )}
          <button
            onClick={handleOptionsClick}
            className={`options-button button ${showOptions ? 'active' : ''}`}
            aria-label="Options"
          >
            <i className="fas fa-ellipsis-h"></i>
          </button>
          {showOptions && (
            <div className="options-popup">
              <button onClick={handleReport}>Report</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LikeCommentShareButton.propTypes = {
  postId: PropTypes.string.isRequired,
  commentLabel: PropTypes.string,
  shareLabel: PropTypes.string,
};

LikeCommentShareButton.defaultProps = {
  commentLabel: 'Comment',
  shareLabel: 'Share',
};

export default LikeCommentShareButton;
