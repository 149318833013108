import React, { useState, useRef, useEffect } from 'react';
import DOMPurify from 'dompurify'; // Sanitize input
import { jwtDecode } from 'jwt-decode'; // Corrected import
import './CreatePost.css'; // Styling
import { uploadPost } from '../services/apiService'; // Import the uploadPost API route

const MAX_FILE_SIZE_MB = 125; // Max file size in MB
const SUPPORTED_MEDIA_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp', 'image/gif', 'video/mp4']; // Allowed MIME types for media files
const SUPPORTED_THUMBNAIL_TYPES = ['image/jpeg', 'image/png', 'image/jpg']; // Allowed MIME types for thumbnails (excluding GIFs)

const CreatePost = () => {
  const [description, setDescription] = useState('');
  const [mediaFiles, setMediaFiles] = useState([]); // Store selected media files with optional thumbnails
  const [errors, setErrors] = useState(''); // Store any error messages
  const [loading, setLoading] = useState(false); // Track upload status
  const [username, setUsername] = useState(''); // Store actual user info
  const fileInputRef = useRef(null); // File input ref for triggering click
  const dropRef = useRef(null); // Ref for drag and drop

  useEffect(() => {
    const loadUserInfo = () => {
      try {
        const token = localStorage.getItem('accessToken'); // Get the token from localStorage
        if (token) {
          const decodedToken = jwtDecode(token); // Decode the token to get user info
          if (decodedToken && decodedToken.username) {
            setUsername(decodedToken.username);
          }
        }
      } catch (error) {
        console.error('Error decoding user information from token:', error);
        setErrors('Error loading user information. Please try again.');
      }
    };

    loadUserInfo();
  }, []);

  // Handle file selection via input, drag and drop, or paste
  const handleFiles = (files) => {
    const newFiles = [];

    Array.from(files).forEach((file) => {
      if (!SUPPORTED_MEDIA_TYPES.includes(file.type)) {
        setErrors(`Unsupported file type: ${file.name}`);
        return; // Skip unsupported files
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setErrors(`File too large: ${file.name} exceeds ${MAX_FILE_SIZE_MB}MB`);
        return; // Skip large files
      }
      newFiles.push({
        file,
        url: URL.createObjectURL(file),
        thumbnail: null, // Initialize thumbnail as null
        thumbnailUrl: null, // Initialize thumbnail URL as null
      });
    });

    if (newFiles.length > 0) {
      setMediaFiles((prevFiles) => [...prevFiles, ...newFiles]); // Append valid files
      setErrors(''); // Clear errors if valid files are added
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files; // FileList from input
    if (files && files.length > 0) {
      handleFiles(files);
    }
  };

  const handleThumbnailChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      if (!SUPPORTED_THUMBNAIL_TYPES.includes(file.type)) {
        setErrors(`Unsupported thumbnail type: ${file.name}. Only JPEG and PNG are allowed.`);
        return;
      }
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setErrors(`Thumbnail too large: ${file.name} exceeds ${MAX_FILE_SIZE_MB}MB`);
        return;
      }

      setMediaFiles((prevFiles) =>
        prevFiles.map((media, i) =>
          i === index
            ? { 
                ...media, 
                thumbnail: file,
                thumbnailUrl: URL.createObjectURL(file), // Add thumbnailUrl for preview and revocation
              } 
            : media
        )
      );
      setErrors('');
    }
  };

  // Function to remove a media file by its index
  const handleRemoveMedia = (index) => {
    setMediaFiles((prevFiles) => {
      // Revoke the Object URL to prevent memory leaks
      URL.revokeObjectURL(prevFiles[index].url);
      if (prevFiles[index].thumbnailUrl) {
        URL.revokeObjectURL(prevFiles[index].thumbnailUrl);
      }
      // Return the new array without the removed file
      return prevFiles.filter((_, i) => i !== index);
    });
  };

  const handleAttachMediaClick = () => {
    fileInputRef.current.click(); // Trigger the hidden file input
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!description && mediaFiles.length === 0) {
      setErrors('Please provide a description or attach media.');
      return; // Prevent empty post submission
    }

    if (!username) {
      setErrors('User information is not available. Please try again.');
      return; // Prevent submission if username is not loaded
    }

    setLoading(true); // Set loading state during submission
    const sanitizedDescription = DOMPurify.sanitize(description); // Sanitize input

    const formData = new FormData();
    formData.append('description', sanitizedDescription);
    formData.append('username', username);

    mediaFiles.forEach((mediaFile) => {
      formData.append('mediaFiles', mediaFile.file);
      if (mediaFile.thumbnail) {
        formData.append('thumbnails', mediaFile.thumbnail); // Append thumbnails
      }
    });

    try {
      const data = await uploadPost(formData); // Use the uploadPost function from apiService
      console.log('Post uploaded successfully:', data);

      // Clear the form on success
      setDescription('');
      setMediaFiles([]);
      setErrors('');
    } catch (error) {
      console.error('Upload error:', error);
      // Display backend error message if available
      if (error.response && error.response.data && error.response.data.message) {
        setErrors(error.response.data.message);
      } else {
        setErrors('Error uploading post. Please try again.');
      }
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  // Drag and Drop Handlers
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Optionally, add visual feedback for drag over
    if (dropRef.current) {
      dropRef.current.classList.add('drag-over');
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Remove visual feedback
    if (dropRef.current) {
      dropRef.current.classList.remove('drag-over');
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Remove visual feedback
    if (dropRef.current) {
      dropRef.current.classList.remove('drag-over');
    }

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFiles(files);
      e.dataTransfer.clearData();
    }
  };

  // Paste Handler
  const handlePaste = (e) => {
    if (e.clipboardData) {
      const items = e.clipboardData.items;
      const files = [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
          const file = items[i].getAsFile();
          if (file) {
            files.push(file);
          }
        }
      }
      if (files.length > 0) {
        handleFiles(files);
      }
    }
  };

  useEffect(() => {
    const currentDropRef = dropRef.current;

    if (currentDropRef) {
      currentDropRef.addEventListener('dragover', handleDragOver);
      currentDropRef.addEventListener('dragleave', handleDragLeave);
      currentDropRef.addEventListener('drop', handleDrop);
      window.addEventListener('paste', handlePaste);
    }

    // Cleanup event listeners on unmount
    return () => {
      if (currentDropRef) {
        currentDropRef.removeEventListener('dragover', handleDragOver);
        currentDropRef.removeEventListener('dragleave', handleDragLeave);
        currentDropRef.removeEventListener('drop', handleDrop);
      }
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <div 
      className="create-post" 
      ref={dropRef} // Attach ref for drag and drop
      // Optionally, you can set role and aria attributes for accessibility
      role="region" 
      aria-label="Create a new post"
    >
      <form onSubmit={handleSubmit}>
        <div className="resizable-textarea">
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="What's on your mind?"
          />
        </div>

        {errors && <div className="error-message">{errors}</div>} {/* Display errors */}

        <div className="media-preview">
          {mediaFiles.map((mediaFile, index) => (
            <div key={index} className="media-item">
              {/* Media Content */}
              {mediaFile.file.type.startsWith('video/') ? (
                <>
                  <video src={mediaFile.url} controls width="100%" />
                  <div className="thumbnail-upload">
                    <label htmlFor={`thumbnail-upload-${index}`}>Choose Thumbnail</label>
                    <input
                      id={`thumbnail-upload-${index}`}
                      type="file"
                      accept="image/jpeg, image/png" // Restrict to JPEG and PNG
                      onChange={(e) => handleThumbnailChange(e, index)}
                    />
                    {mediaFile.thumbnailUrl && (
                      <div className="thumbnail-preview">
                        <img
                          src={mediaFile.thumbnailUrl}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <img src={mediaFile.url} alt={`preview-${index}`} width="100%" />
              )}

              {/* Remove Button */}
              <button
                type="button"
                className="remove-button"
                onClick={() => handleRemoveMedia(index)}
                aria-label={`Remove media file ${index + 1}`}
              >
                &times;
              </button>
            </div>
          ))}
        </div>

        <div className="button-group">
          <button type="button" onClick={handleAttachMediaClick}>
            Attach Media
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            multiple
            accept="image/jpeg, image/jpg, image/png, image/gif, video/mp4" // Restrict to images and videos
            onChange={handleFileChange}
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Posting...' : 'Post'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePost;
