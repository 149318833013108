// src/components/SearchResultsPage.js
import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { search, api } from '../services/apiService';
import Post from './Post'; // Importing Post component for reuse
import './SearchResultsPage.css'; // Make sure to import your updated CSS

// Import the SEO component and seoConfig
import SEO from './SEO'; // Adjust the path if SEO.js is in a different directory
import seoConfig from './seoConfig'; // Adjust the path based on your project structure

const SearchResultsPage = () => {
  const [users, setUsers] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [error, setError] = useState(null);
  const [profileImageCache, setProfileImageCache] = useState({});

  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query') || '';

  useEffect(() => {
    if (query.trim() !== '') {
      const fetchSearchResults = async () => {
        setLoadingUsers(true);
        setLoadingPosts(true);
        setError(null);
        try {
          const data = await search(query);
          setUsers(data.users);
          setPosts(data.posts);
          await fetchProfileImages(data.users, data.posts);
        } catch (error) {
          console.error('Error fetching search results:', error);
          setError('Failed to fetch search results. Please try again.');
        } finally {
          setLoadingUsers(false);
          setLoadingPosts(false);
        }
      };

      fetchSearchResults();
    } else {
      setUsers([]);
      setPosts([]);
      setLoadingUsers(false);
      setLoadingPosts(false);
    }
  }, [query]);

  const fetchProfileImages = async (users, posts) => {
    try {
      const userAndPostNames = [
        ...new Set([...users.map((u) => u.username), ...posts.map((p) => p.username)]),
      ];
      
      const promises = userAndPostNames.map(async (username) => {
        if (!profileImageCache[username]) {
          const response = await api.get(`/users/${username}`);
          if (response.status === 200) {
            setProfileImageCache((prev) => ({
              ...prev,
              [username]: response.data.profileImage,
            }));
          }
        }
      });

      await Promise.all(promises);
    } catch (error) {
      console.error('Error fetching profile images:', error);
    }
  };

  // Determine SEO metadata based on the search query
  const seoData = seoConfig.search(query);

  return (
    <div className="search-results">
      {/* Add SEO component with dynamic data */}
      <SEO title={seoData.title} description={seoData.description} />

      <h2>Search Results for "{query}"</h2>

      <div className="users-section">
        <h3>Users</h3>
        {loadingUsers ? (
          <div>Loading users...</div>
        ) : users.length > 0 ? (
          users.map((user) => (
            <div key={user._id} className="user-item">
              <div className="user-info">
                {profileImageCache[user.username] ? (
                  <img
                    src={profileImageCache[user.username]}
                    alt={`${user.username}'s profile`}
                    className="profile-image"
                  />
                ) : (
                  <div className="profile-placeholder">No Image</div>
                )}
                <div>
                  <h2 className="username">
                    <Link to={`/profile/${user.username}`}>{user.username}</Link>
                  </h2>
                  <p className="user-bio">{user.bio}</p>
                </div>
              </div>
              <button className="follow-button">Follow</button>
            </div>
          ))
        ) : (
          <p>No users found</p>
        )}
      </div>

      <div className="posts-section">
        <h3>Posts</h3>
        {loadingPosts ? (
          <div>Loading posts...</div>
        ) : posts.length > 0 ? (
          posts.map((post) => <Post key={post._id} post={post} />) // Reusing Post component
        ) : (
          <p>No posts found</p>
        )}
      </div>

      {error && (
        <div className="error-message">
          <p>Error: {error}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      )}
    </div>
  );
};

export default SearchResultsPage;
