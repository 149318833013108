import axios from 'axios';

// Base URL for the API
const API_BASE_URL = 'https://neondesire.com/api';

// Temporary storage for the access token in memory
let accessToken = localStorage.getItem('accessToken') || null;

// Create an Axios instance for authenticated requests
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Ensure cookies are sent with requests
});

// Create a public Axios instance for unauthenticated requests
const publicApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Include cookies for public requests if needed
});

// Interceptor to attach the access token to each request
api.interceptors.request.use(
  (config) => {
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`; // Attach token
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Updated Interceptor to handle 401 and 403 errors
api.interceptors.response.use(
  (response) => response, // Pass through valid responses
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized or 403 Forbidden
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Mark the request as retried

      try {
        console.log('Attempting to refresh token...');

        // Request a new access token using the refresh token
        const response = await publicApi.post(
          '/auth/refresh-token',
          {},
          { withCredentials: true }
        );

        const newToken = response.data.accessToken;

        if (newToken) {
          // Update access token in memory and localStorage
          accessToken = newToken;
          localStorage.setItem('accessToken', newToken);

          // Set the new token in headers for the retry request
          originalRequest.headers['Authorization'] = `Bearer ${newToken}`;

          // Retry the original request with the new token
          return api(originalRequest);
        } else {
          throw new Error('No access token returned');
        }
      } catch (refreshError) {
        console.error(
          'Failed to refresh token:',
          refreshError.response?.data || refreshError.message
        );

      }
    }

    // Reject other errors
    return Promise.reject(error);
  }
);

// Function to log the user in
export const login = async (email, password) => {
  try {
    console.log(`Logging in with email: ${email}`);

    const response = await publicApi.post(
      '/auth/login',
      { email, password },
      { withCredentials: true }
    );

    if (response.status === 200) {
      const { accessToken: newToken } = response.data;

      // Store the new access token
      accessToken = newToken;
      localStorage.setItem('accessToken', newToken);

      // Set the token for future requests
      api.defaults.headers['Authorization'] = `Bearer ${newToken}`;

      console.log('User logged in successfully');
      return response.data;
    }
  } catch (error) {
    console.error('Login failed:', error.response?.data || error.message);
    throw error;
  }
};

// Function to sign the user up
export const signup = async (username, email, password) => {
  try {
    console.log(`Signing up with email: ${email}`);

    const response = await publicApi.post(
      '/auth/signup',
      { username, email, password },
      { withCredentials: true }
    );

    if (response.status === 201) {
      console.log('User signed up successfully');

      // Handle the accessToken returned by the server
      const { accessToken: newToken } = response.data;
      if (newToken) {
        // Store the new access token
        accessToken = newToken;
        localStorage.setItem('accessToken', newToken);

        // Set the token for future requests
        api.defaults.headers['Authorization'] = `Bearer ${newToken}`;
      }

      return response.data;
    }
  } catch (error) {
    console.error('Signup failed:', error.response?.data || error.message);
    throw error;
  }
};

// Function to log the user out
export const logout = async () => {
  try {
    await api.post('/auth/logout');
    accessToken = null; // Clear token from memory
    localStorage.removeItem('accessToken'); // Remove token from localStorage
    console.log('Logged out successfully');
    window.location.href = '/login'; // Redirect to login page
  } catch (error) {
    console.error('Logout failed:', error.response?.data || error.message);
  }
};

// Function to fetch user information by username
export const fetchUserInfo = async (username) => {
  try {
    const response = await api.get(`/users/${username}`);

    if (response.status === 200) {
      console.log(`User information for ${username} fetched successfully`);
      return response.data;
    }
  } catch (error) {
    console.error(
      `Failed to fetch user information for ${username}:`,
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch posts by user ID
export const fetchUserPosts = async (userId) => {
  try {
    const response = await api.get(`/posts/user/${userId}`);

    if (response.status === 200) {
      console.log(`Posts for user ID ${userId} fetched successfully`);
      return response.data;
    }
  } catch (error) {
    console.error(
      `Failed to fetch posts for user ID ${userId}:`,
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch discover feed
export const fetchDiscoverFeed = async (page = 1) => {
  try {
    const response = await publicApi.get(`/feed/discover?page=${page}`);
    if (response.status === 200) {
      console.log('Discover feed fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error(
      'Failed to fetch discover feed:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch following feed
export const fetchFollowingFeed = async (page = 1) => {
  try {
    const response = await api.get(`/feed/following?page=${page}`);
    if (response.status === 200) {
      console.log('Following feed fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error(
      'Failed to fetch following feed:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch shorts feed
export const fetchShortsFeed = async (page = 1) => {
  try {
    const response = await publicApi.get(`/shorts/discover?page=${page}`);
    if (response.status === 200) {
      console.log('Shorts feed fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error(
      'Failed to fetch shorts feed:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch videos feed
export const fetchVideosFeed = async (page = 1) => {
  try {
    const response = await publicApi.get(`/videos/discover?page=${page}`);
    if (response.status === 200) {
      console.log('Videos feed fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error(
      'Failed to fetch videos feed:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to fetch photos feed
export const fetchPhotosFeed = async (page = 1) => {
  try {
    const response = await publicApi.get(`/photos/discover?page=${page}`);
    if (response.status === 200) {
      console.log('Photos feed fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error(
      'Failed to fetch photos feed:',
      error.response?.data || error.message
    );
    throw error;
  }
};

// Function to upload a post
export const uploadPost = async (formData) => {
  try {
    const response = await api.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('Post uploaded successfully');
    return response.data; // Return response data for further processing
  } catch (error) {
    console.error('Error uploading post:', error.response?.data || error.message);
    throw error;
  }
};





// mod stuff


// Function to report a post
export const reportRoute = async (postId, reason, token) => {
  try {
    const response = await api.post(
      '/report',
      { postId, reason },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error reporting post:', error.response?.data || error.message);
    throw error;
  }
};




// Function to fetch reports for moderators
export const fetchModQueue = async () => {
  try {
    const response = await api.get('/mod/queue');
    if (response.status === 200) {
      console.log('Mod queue fetched successfully');
      return response.data;
    }
  } catch (error) {
    console.error('Failed to fetch mod queue:', error.response?.data || error.message);
    throw error;
  }
};

// Function to take action on reported posts (approve or remove)
export const takeModAction = async (reportId, action) => {
  try {
    const response = await api.post('/mod/action', { reportId, action });
    if (response.status === 200) {
      console.log('Action performed successfully');
      return response.data;
    }
  } catch (error) {
    console.error('Failed to perform mod action:', error.response?.data || error.message);
    throw error;
  }
};






// search stuff



// Function to perform a search for users or posts
export const search = async (query) => {
  try {
    console.log(`Initiating search with query: ${query}`); // Debug log for initial query

    // Using the publicApi to fetch the search results
    const response = await publicApi.get('/search', {
      params: { query: encodeURIComponent(query) },
    });

    console.log('Search API response:', response); // Debug log for entire response

    if (response.status === 200) {
      console.log('Search results fetched successfully');
      return response.data;
    } else {
      console.warn(`Unexpected response status: ${response.status}`); // Warn if response status is unexpected
    }
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code outside of the 2xx range
      console.error('Error response from server:', {
        status: error.response.status,
        headers: error.response.headers,
        data: error.response.data,
      });
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received from server:', {
        request: error.request,
      });
    } else {
      // Something happened in setting up the request that triggered an error
      console.error('Error setting up search request:', {
        message: error.message,
      });
    }

    console.error('Full error object:', error); // Log the full error object for detailed debugging
    throw error;
  }
};



// follow routes

// Follow a user
export const followUser = async (username, token) => {
  try {
    const response = await api.post(`/follow/follow/${username}`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error following user:', error);
    throw error;
  }
};

//unfollow
export const unfollowUser = async (username, token) => {
  try {
    const response = await api.post(`/follow/unfollow/${username}`, {}, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error('Error unfollowing user:', error);
    throw error;
  }
};





export { api, publicApi };
