// Footer.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for routing
import './Footer.css'; // Import the corresponding CSS
import { FaTwitter, FaDiscord } from 'react-icons/fa'; // Social media icons

const Footer = React.memo(() => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Section 1: General Links */}
        <div className="footer-section">
          <h4>About</h4>
          <ul>
            <li><Link to="/about">About Us</Link></li>
            <li><Link to="/contact">Contact</Link></li>
            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            <li><Link to="/terms-of-service">Terms of Service</Link></li>
            <li><Link to="/ProhibitedContentPolicy">Prohibited Content Policy</Link></li>
          </ul>
        </div>

        {/* Section 2: Categories */}
        <div className="footer-section">
          <h4>Categories</h4>
          <ul>
            <li><Link to="/videos">Videos</Link></li>
            <li><Link to="/shorts">Shorts</Link></li>
            <li><Link to="/photos">Photos</Link></li>
            <li>
              <Link
                to="/live-streams"
                style={{ pointerEvents: 'none', opacity: 0.5 }}
              >
                Live Streams - Coming Soon
              </Link>
            </li>
          </ul>
        </div>

        {/* Section 3: Social Media */}
        <div className="footer-section">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://x.com/Neon_Desire1" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="social-icon" />
            </a>
            <a href="https://discord.gg/pAN9k69Sc9" target="_blank" rel="noopener noreferrer">
            <FaDiscord className="social-icon" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Neon Desire. All rights reserved.</p>
      </div>
    </footer>
  );
});

export default Footer;
