// SEO.js Component
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

// SEO Component that takes in title, description, and url as props
const SEO = ({ title, description }) => {
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {/* Canonical link to prevent duplicate content */}
      <link rel="canonical" href={currentUrl} />
    </Helmet>
  );
};

export default SEO;
