import React, { useEffect, useState } from 'react';

const AgeVerification = () => {
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        // Check if age is already verified
        const ageVerified = localStorage.getItem('ageVerified');
        if (ageVerified === 'true') {
            setIsVerified(true);
        }
    }, []);

    const handleVerification = (isOldEnough) => {
        if (isOldEnough) {
            // Save verification to localStorage
            localStorage.setItem('ageVerified', 'true');
            setIsVerified(true);
        } else {
            // Redirect underage users
            window.location.href = 'https://neondesire.com/terms-of-service'; // Replace with appropriate URL
        }
    };

    if (isVerified) return null; // Do not display popup if verified

    return (
        <>
            {/* Background Blur Overlay */}
            <div style={overlayStyles}></div>

            {/* Popup Container */}
            <div style={popupContainerStyles}>
                <h2>Age Verification</h2>
                <p>You must be 18 years or older to enter this site.</p>
                <button style={buttonStyles} onClick={() => handleVerification(true)}>
                    I am 18 or older
                </button>
                <button style={buttonStyles} onClick={() => handleVerification(false)}>
                    I am under 18
                </button>
            </div>
        </>
    );
};

// Styles
const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(24px)', // Apply blur effect here
    zIndex: 9998 // Place it below the popup container
};

const popupContainerStyles = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#2e2e2e',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '300px',
    color: '#fff', // Set text color to white
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
    zIndex: 9999 // Place it above the blur overlay
};

const buttonStyles = {
    padding: '10px 20px',
    fontSize: '1em',
    border: 'none',
    cursor: 'pointer',
    margin: '10px',
    color: '#fff',
    backgroundColor: '#ff7f50',
    borderRadius: '5px',
    transition: 'background-color 0.3s ease'
};

export default AgeVerification;
