// src/components/AboutPage.js
import React from 'react';
import './AboutPage.css'; // Scoped CSS for AboutPage

// Import the SEO component and seoConfig
import SEO from './SEO'; // Adjust the path if SEO.js is in a different directory
import seoConfig from './seoConfig'; // Adjust the path based on your project structure

const AboutPage = () => {
  // Retrieve SEO data for the About page from seoConfig
  const seoData = seoConfig.about;

  return (
    <div className="about-page">
      {/* Add SEO component with static data */}
      <SEO title={seoData.title} description={seoData.description} />

      <section className="about-page__intro">
        <h1>Welcome to Neon Desire</h1>
        <p>
          Neon Desire offers a modern, interactive platform for creative expression through
          photos, videos, live streams, and more. Customize your experience with our intuitive
          features designed to enhance how you engage with creators and content.
        </p>
      </section>

      <section className="about-page__mission-vision">
        <h2>Mission & Vision</h2>
        <p>
          At Neon Desire, we are committed to providing a space for creators to thrive while
          ensuring users have a top-tier entertainment experience. Our mission is rooted in 
          ethical practices, transparency, and respect for every interaction.
        </p>
      </section>

      <section className="about-page__key-features">
        <h2>Key Features</h2>
        <ul>
          <li>Customizable feeds with filters to tailor content to your preferences.</li>
          <li>Save and organize content with public or private collections.</li>
          <li>Direct support for creators through tips and subscriptions.</li>
          <li>Profile-based categories: photos, short-form, long-form, and live streams.</li>
        </ul>
      </section>

      <section className="about-page__ethics">
        <h2>Our Ethical Approach</h2>
        <p>
          Neon Desire is committed to fair practices and authentic interactions. We prioritize 
          transparency and foster a responsible environment, ensuring that all content is 
          genuine and free from AI generation.
        </p>
      </section>

      <section className="about-page__community-safety">
        <h2>Community & Safety</h2>
        <p>
          We provide tools for reporting, flagging, and moderating content to maintain a safe 
          environment. Privacy and data security are paramount, and we use the latest technologies 
          to protect both users and creators.
        </p>
      </section>

      <section className="about-page__privacy-security">
        <h2>Privacy & Security</h2>
        <p>
          We leverage top practices that platforms like Instagram, Twitter, and Google use to 
          ensure top-notch security and compliance with industry standards. Your data privacy 
          is our priority.
        </p>
      </section>

      <section className="about-page__getting-started">
        <h2>How to Get Started</h2>
        <ol>
          <li>Create an account and explore content from top creators.</li>
          <li>Follow your favorite creators to stay updated on new content.</li>
          <li>Start posting your own content and build your following.</li>
        </ol>
      </section>

      <section className="about-page__contact-support">
        <h2>Contact & Support</h2>
        <p>
          Have questions or need support? Reach out to our team through the help section or 
          contact us directly.
        </p>
      </section>

      <section className="about-page__future-development">
        <h2>Platform Future & Development</h2>
        <p>
          We are constantly working to enhance the Neon Desire experience. Stay tuned for 
          exciting new features and improvements as we grow with our community.
        </p>
      </section>
    </div>
  );
};

export default AboutPage;
