import React, { useRef, useState, useEffect, useCallback } from 'react';
import './VideoPlayer.css';
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaCog,
  FaStepForward,
  FaStepBackward,
  FaCompressArrowsAlt,
  FaExpandArrowsAlt,
} from 'react-icons/fa';
import PropTypes from 'prop-types';

const VideoPlayer = ({
  src = '',
  poster,
  qualityOptions = [],
  thumbnails = [],
  onWatchTimeUpdate,
  customTheme = '',
}) => {
  const videoRef = useRef(null);
  const progressRef = useRef(null);
  const volumeSliderRef = useRef(null);
  const containerRef = useRef(null);

  // State Variables
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volume, setVolume] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [currentQuality, setCurrentQuality] = useState(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [skipIndicator, setSkipIndicator] = useState(null);
  const [pauseIndicator, setPauseIndicator] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [currentTime, setCurrentTime] = useState('0:00');
  const [duration, setDuration] = useState('0:00');
  const [isPiP, setIsPiP] = useState(false);
  const [hoverTime, setHoverTime] = useState(null);
  const [hoverPosition, setHoverPosition] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [touchStartTime, setTouchStartTime] = useState(0);
  const [doubleTapTimeout, setDoubleTapTimeout] = useState(null);
  const [error, setError] = useState(null);
  const [videoSrc, setVideoSrc] = useState(src); // Initialize with src

  // Initialize Volume and Mute State
  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.volume = volume;
      video.muted = isMuted;
    }
  }, [volume, isMuted]);

  // Handle Window Resize to Detect Mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Set Video Source Based on qualityOptions and src
  useEffect(() => {
    if (qualityOptions.length > 0) {
      // Validate that each quality option has a 'src'
      const validQualityOptions = qualityOptions.filter(q => q.src);
      if (validQualityOptions.length === 0) {
        setError('No valid video source available in quality options.');
        setVideoSrc(''); // Clear videoSrc since no valid sources
        return;
      }
      setCurrentQuality(validQualityOptions[0]);
      setVideoSrc(validQualityOptions[0].src);
      setError(null);
    } else if (src) {
      setVideoSrc(src);
      setCurrentQuality(null);
      setError(null);
    } else {
      setVideoSrc('');
      setCurrentQuality(null);
      setError('No video source provided.');
    }
  }, [qualityOptions, src]);

  // Update Video Element Source When videoSrc Changes
  useEffect(() => {
    const video = videoRef.current;
    if (videoSrc) {
      video.src = videoSrc;
      video.load(); // Reload the video with the new source
      video.onloadedmetadata = () => {
        setDuration(formatTime(video.duration));
      };
      video.onended = handleVideoEnd;
      video.onerror = () => {
        // Provide detailed error messages based on error codes
        let errorMessage = 'An error occurred while playing the video.';
        if (video.error) {
          switch (video.error.code) {
            case video.error.MEDIA_ERR_ABORTED:
              errorMessage = 'Video playback was aborted.';
              break;
            case video.error.MEDIA_ERR_NETWORK:
              errorMessage = 'A network error caused the video download to fail.';
              break;
            case video.error.MEDIA_ERR_DECODE:
              errorMessage = 'The video playback was aborted due to a corruption problem or because the video used features your browser did not support.';
              break;
            case video.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
              errorMessage = 'The video could not be loaded, either because the server or network failed or because the format is not supported.';
              break;
            default:
              errorMessage = 'An unknown error occurred.';
              break;
          }
        }
        setError(errorMessage);
        console.error('Video Error:', video.error);
      };
    } else {
      // If videoSrc is empty, set error only if neither qualityOptions nor src are provided
      if (!qualityOptions.length && !src) {
        setError('No video source available.');
      }
    }
  }, [videoSrc, qualityOptions, src]);

  // Format Time Helper Function
  const formatTime = (timeInSeconds) => {
    if (!timeInSeconds || isNaN(timeInSeconds)) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  // Handle Video Ended Event
  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
    // Removed autoplayNext logic
  }, []);

  // Play/Pause Toggle Handler
  const handlePlayPause = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;

    if (isPlaying) {
      video.pause();
      setPauseIndicator(true);
      setTimeout(() => setPauseIndicator(false), 500);
    } else {
      video.play().catch((err) => {
        setError('Unable to play the video.');
        console.error(err);
      });
    }
    setIsPlaying(!isPlaying);
  }, [isPlaying]);

  // Mute/Unmute Toggle Handler
  const handleMuteToggle = useCallback(() => {
    setIsMuted((prev) => !prev);
  }, []);

  // Volume Change Handler
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  // Fullscreen Toggle Handler
  const handleFullScreenToggle = useCallback(() => {
    const videoContainer = containerRef.current;
    if (!videoContainer) return;

    if (!isFullScreen) {
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.webkitRequestFullscreen) {
        videoContainer.webkitRequestFullscreen();
      } else if (videoContainer.msRequestFullscreen) {
        videoContainer.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  // Progress Bar Change Handler
  const handleProgressChange = (e) => {
    const video = videoRef.current;
    if (!video) return;
    const newTime = (e.target.value / 100) * video.duration;
    video.currentTime = newTime;
  };

  // Video Time Update Handler
  const handleTimeUpdate = () => {
    const video = videoRef.current;
    if (!video) return;
    const progress = (video.currentTime / video.duration) * 100;
    if (progressRef.current) {
      progressRef.current.value = progress;
    }
    setCurrentTime(formatTime(video.currentTime));
    if (onWatchTimeUpdate) {
      onWatchTimeUpdate(video.currentTime);
    }
  };

  // Playback Rate Change Handler
  const handlePlaybackRateChange = (e) => {
    const rate = parseFloat(e.target.value);
    if (videoRef.current) {
      videoRef.current.playbackRate = rate;
    }
    setPlaybackRate(rate);
  };

  // Quality Change Handler
  const handleQualityChange = useCallback(
    (e) => {
      const selectedQuality = qualityOptions.find(
        (q) => q.label === e.target.value
      );
      if (selectedQuality && selectedQuality.src) {
        const wasPlaying = isPlaying;
        setCurrentQuality(selectedQuality);
        setVideoSrc(selectedQuality.src);
        setError(null); // Clear any previous errors
        if (wasPlaying) {
          videoRef.current.play().catch((err) => {
            setError('Unable to play the video.');
            console.error(err);
          });
        }
      }
    },
    [qualityOptions, isPlaying]
  );

  // Show Controls on Mouse Enter
  const handleMouseEnter = () => {
    setShowControls(true);
  };

  // Hide Controls on Mouse Leave (Only if Playing)
  const handleMouseLeave = () => {
    if (!isPlaying) return;
    setShowControls(false);
  };

  // Skip Forward/Backward Handler
  const handleSkip = useCallback(
    (seconds) => {
      const video = videoRef.current;
      if (!video) return;
      video.currentTime += seconds;
      setSkipIndicator(seconds > 0 ? `+${seconds}s` : `${seconds}s`);
      setTimeout(() => setSkipIndicator(null), 1000);
    },
    []
  );

  // Picture-in-Picture Toggle Handler
  const handlePictureInPicture = useCallback(() => {
    const video = videoRef.current;
    if (!video) return;
    if (isPiP) {
      document.exitPictureInPicture().catch((error) => {
        console.error('Error exiting Picture-in-Picture mode:', error);
      });
    } else if (video.requestPictureInPicture) {
      video.requestPictureInPicture().catch((error) => {
        console.error('Error entering Picture-in-Picture mode:', error);
      });
    }
    setIsPiP(!isPiP);
  }, [isPiP]);

  // Video Click Handler (Play/Pause)
  const handleVideoClick = () => {
    handlePlayPause();
  };

  // Video Double Click Handler (Fullscreen)
  const handleDoubleClick = () => {
    handleFullScreenToggle();
  };

  // Volume Adjustment via Wheel (Optional)
  const handleWheel = useCallback(
    (e) => {
      e.preventDefault();
      let newVolume = volume + (e.deltaY < 0 ? 0.05 : -0.05);
      newVolume = Math.min(Math.max(newVolume, 0), 1);
      setVolume(newVolume);
      setIsMuted(newVolume === 0);
    },
    [volume]
  );

  // Attach the wheel event listener manually with passive: false
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      container.removeEventListener('wheel', handleWheel);
    };
  }, [handleWheel]);

  // Progress Bar Hover Handler
  const handleProgressMouseMove = (e) => {
    const progressBar = progressRef.current;
    if (!progressBar) return;
    const rect = progressBar.getBoundingClientRect();
    const percent = ((e.clientX - rect.left) / rect.width) * 100;
    const video = videoRef.current;
    if (!video) return;
    const time = (percent / 100) * video.duration;
    setHoverTime(formatTime(time));
    setHoverPosition(e.clientX - rect.left);
  };

  const handleProgressMouseLeave = () => {
    setHoverTime(null);
    setHoverPosition(null);
  };

  // Touch Handler for Mobile Devices
  const handleTouchStart = (e) => {
    const now = Date.now();
    if (now - touchStartTime < 300 && doubleTapTimeout) {
      clearTimeout(doubleTapTimeout);
      setDoubleTapTimeout(null);
      const touch = e.touches[0];
      const middle = window.innerWidth / 2;
      if (touch.clientX < middle) {
        handleSkip(-10);
      } else {
        handleSkip(10);
      }
    } else {
      setTouchStartTime(now);
      const timeout = setTimeout(() => {
        handlePlayPause();
        setDoubleTapTimeout(null);
      }, 300);
      setDoubleTapTimeout(timeout);
    }
  };

  // CSS Class Names
  const videoClassNames = `video-player ${
    isFullScreen ? 'fullscreen' : ''
  } ${customTheme ? customTheme : ''}`;

  return (
    <div
      className={videoClassNames}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      tabIndex="0"
      ref={containerRef}
    >
      {/* Error Message */}
      {error && (
        <div className="error-message">
          {error}
          {/* Retry Button Only if Video Source is Missing */}
          {!videoSrc && (
            <button
              onClick={() => {
                if (qualityOptions.length > 0) {
                  setVideoSrc(qualityOptions[0].src);
                } else {
                  setVideoSrc(src);
                }
              }}
            >
              Retry
            </button>
          )}
        </div>
      )}

      {/* Video Element */}
      <video
        ref={videoRef}
        poster={poster}
        onTimeUpdate={handleTimeUpdate}
        onWaiting={() => setIsBuffering(true)}
        onPlaying={() => setIsBuffering(false)}
        controls={false}
        onClick={handleVideoClick}
        onDoubleClick={handleDoubleClick}
        onTouchStart={isMobile ? handleTouchStart : null}
        onError={() => setError('An error occurred while playing the video.')}
      />

      {/* Buffering Indicator */}
      {isBuffering && <div className="buffering-indicator">Buffering...</div>}

      {/* Skip Indicator */}
      {skipIndicator && <div className="skip-indicator">{skipIndicator}</div>}

      {/* Pause Indicator */}
      {pauseIndicator && <div className="pause-indicator">Paused</div>}

      {/* Thumbnail Preview on Hover */}
      {hoverTime && (
        <div
          className="thumbnail-preview"
          style={{ left: hoverPosition - 50 }}
        >
          {thumbnails && thumbnails.length > 0 && (
            <img
              src={
                thumbnails[
                  Math.min(
                    Math.floor(
                      (videoRef.current.duration *
                        (hoverPosition / (progressRef.current?.offsetWidth || 1))) /
                        thumbnails.length
                    ),
                    thumbnails.length - 1
                  )
                ]
              }
              alt="Thumbnail"
            />
          )}
          <div className="time-indicator">{hoverTime}</div>
        </div>
      )}

      {/* Controls Container */}
      {showControls && (
        <div className="controls-container">
          {/* Play/Pause Button */}
          <button onClick={handlePlayPause} aria-label={isPlaying ? 'Pause' : 'Play'}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </button>

          {/* Volume Control */}
          <div className="volume-control-wrapper">
            <div className="volume-control">
              <button onClick={handleMuteToggle} aria-label={isMuted || volume === 0 ? 'Unmute' : 'Mute'}>
                {isMuted || volume === 0 ? <FaVolumeMute /> : <FaVolumeUp />}
              </button>
              <input
                ref={volumeSliderRef}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={handleVolumeChange}
                className="volume-slider"
                aria-label="Volume Slider"
              />
            </div>
          </div>

          {/* Progress Bar */}
          <div
            className="progress-container"
            onMouseMove={handleProgressMouseMove}
            onMouseLeave={handleProgressMouseLeave}
          >
            <input
              type="range"
              min="0"
              max="100"
              ref={progressRef}
              onChange={handleProgressChange}
              className="progress-bar"
              aria-label="Progress Bar"
            />
            {/* Thumbnail Preview */}
            {hoverTime && (
              <div className="progress-hover-info" style={{ left: hoverPosition }}>
                {hoverTime}
              </div>
            )}
          </div>

          {/* Timestamp */}
          <div className="timestamp">
            {currentTime} / {duration}
          </div>

          {/* Skip Backward Button */}
          <button onClick={() => handleSkip(-10)} aria-label="Skip Backward 10 Seconds">
            <FaStepBackward /> 10s
          </button>

          {/* Skip Forward Button */}
          <button onClick={() => handleSkip(10)} aria-label="Skip Forward 10 Seconds">
            <FaStepForward /> 10s
          </button>

          {/* Picture-in-Picture Button */}
          <button onClick={handlePictureInPicture} aria-label="Toggle Picture-in-Picture">
            PiP
          </button>

          {/* Fullscreen Toggle Button */}
          <button onClick={handleFullScreenToggle} aria-label={isFullScreen ? 'Exit Fullscreen' : 'Enter Fullscreen'}>
            {isFullScreen ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
          </button>

          {/* Settings Button */}
          <button onClick={() => setShowSettings(!showSettings)} aria-label="Settings">
            <FaCog />
          </button>

          {/* Settings Menu */}
          {(qualityOptions.length > 0 || showSettings) && showSettings && (
            <div className="settings-menu">
              {/* Playback Speed */}
              <label>
                Speed:
                <select value={playbackRate} onChange={handlePlaybackRateChange} aria-label="Playback Speed">
                  {[0.25, 0.5, 1, 1.25, 1.5, 1.75, 2].map((rate) => (
                    <option key={rate} value={rate}>
                      {rate}x
                    </option>
                  ))}
                </select>
              </label>

              {/* Quality Selection */}
              {qualityOptions.length > 0 && (
                <label>
                  Quality:
                  <select
                    value={currentQuality?.label || ''}
                    onChange={handleQualityChange}
                    aria-label="Quality Selection"
                  >
                    {qualityOptions.map((option, index) => (
                      <option key={index} value={option.label}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// PropTypes for Validation
VideoPlayer.propTypes = {
  src: (props, propName, componentName) => {
    if (!props.src && (!props.qualityOptions || props.qualityOptions.length === 0)) {
      return new Error(
        `One of props 'src' or 'qualityOptions' was not specified in '${componentName}'.`
      );
    }
    if (props.src && typeof props.src !== 'string') {
      return new Error(`Invalid prop '${propName}' supplied to '${componentName}'. Expected a string.`);
    }
    return null;
  },
  poster: PropTypes.string,
  qualityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    })
  ), // Optional
  thumbnails: PropTypes.arrayOf(PropTypes.string),
  onWatchTimeUpdate: PropTypes.func,
  customTheme: PropTypes.string,
};

export default VideoPlayer;
