import React from 'react';
import './ProhibitedContentPolicy.css'; // Import corresponding CSS for styling

function ProhibitedContentPolicy() {
  return (
    <div className="policy-container">
      <h2>Prohibited Content Policy for Neon Desire</h2>
      <p><strong>Effective Date:</strong> 10/31/2024</p>

      <h3>Introduction</h3>
      <p>
        At Neon Desire, we strive to maintain a safe, respectful, and legally compliant environment for all users. To achieve this, we have established clear guidelines on prohibited content. By using Neon Desire, you agree to adhere to the following policy:
      </p>

      <h3>1. Illegal Content</h3>
      <ul>
        <li>Content that promotes or depicts illegal activities, including but not limited to drug use, violence, or any form of exploitation.</li>
        <li>Any material that violates local, state, national, or international laws or regulations.</li>
      </ul>

      <h3>2. Non-Consensual and Exploitative Content</h3>
      <ul>
        <li>Content that depicts individuals without their consent, including revenge porn or any form of non-consensual intimate media.</li>
        <li>Any form of content involving minors, including depictions, simulations, or representations.</li>
        <li>Exploitative content that involves forced, coerced, or manipulated individuals.</li>
      </ul>

      <h3>3. Harassment and Hate Speech</h3>
      <ul>
        <li>Content that promotes violence, discrimination, or harassment against any individual or group based on race, ethnicity, nationality, religion, gender, gender identity, sexual orientation, disability, or other protected characteristics.</li>
        <li>Bullying, doxing, or threats directed toward other users or individuals.</li>
      </ul>

      <h3>4. Graphic and Violent Content</h3>
      <ul>
        <li>Content that displays extreme violence, gore, or any depiction of harm or injury meant to shock or disgust viewers.</li>
        <li>Any promotion or encouragement of self-harm, suicide, or dangerous behaviors.</li>
      </ul>

      <h3>5. Content Violating Platform Guidelines</h3>
      <ul>
        <li>AI-generated content is strictly prohibited, in line with Neon Desire's commitment to authentic user-generated media.</li>
        <li>Posts must use only the premade tags provided by the platform for organization and consistency.</li>
        <li>Misrepresentation of content or misleading descriptions meant to deceive viewers.</li>
      </ul>

      <h3>6. Spam, Fraud, and Malicious Content</h3>
      <ul>
        <li>Content that constitutes spam, including repetitive or mass posting with no meaningful value.</li>
        <li>Material that contains or promotes phishing, scams, malware, or any form of cyberattack.</li>
        <li>Fraudulent content that impersonates another person or entity.</li>
      </ul>

      <h3>7. Intellectual Property Violations</h3>
      <ul>
        <li>Content that infringes on the copyrights, trademarks, or other intellectual property rights of others.</li>
        <li>Unauthorized use of Neon Desire branding or proprietary assets.</li>
      </ul>

      <h3>8. Privacy Violations</h3>
      <ul>
        <li>Content that reveals private or personally identifiable information of others, such as addresses, phone numbers, or financial information.</li>
        <li>Material that intrudes upon the privacy of individuals.</li>
      </ul>

      <h3>9. Animal Harm</h3>
      <ul>
        <li>Content that depicts or promotes animal abuse or cruelty.</li>
        <li>Content related to illegal animal trade or poaching.</li>
      </ul>

      <h3>10. Self-Promotion and Advertising</h3>
      <ul>
        <li>Unauthorized advertisements or self-promotional content unrelated to user contributions, unless approved by Neon Desire.</li>
        <li>Links to external services or products without prior approval from the platform.</li>
      </ul>

      <h3>11. Restricted Activities</h3>
      <ul>
        <li>Promotion or depiction of human trafficking or any form of modern slavery.</li>
        <li>Content that suggests or supports non-consensual activities or interactions.</li>
      </ul>

      <h3>Enforcement and Reporting</h3>
      <p>
        Neon Desire employs a content review and moderation system to enforce this policy. Users can report any violations using the content flagging system. The platform reserves the right to remove any content that violates this policy and to suspend or terminate accounts found in breach. Users found repeatedly violating the policy may be permanently banned.
      </p>

      <h3>Appeal Process</h3>
      <p>
        If you believe that your content was removed in error, you may submit an appeal through the specified appeal process. Please contact <a href="mailto:support@neondesire.com">support@neondesire.com</a> for assistance.
      </p>

      <h3>Amendments to the Policy</h3>
      <p>
        Neon Desire may update this Prohibited Content Policy periodically. Notice of significant changes will be provided, and your continued use of the platform will constitute acceptance of the new terms.
      </p>

      <h3>Contact Information</h3>
      <p>
        For questions regarding this Prohibited Content Policy or to report a violation, please contact us at <a href="mailto:support@neondesire.com">support@neondesire.com</a>.
      </p>
    </div>
  );
}

export default ProhibitedContentPolicy;
