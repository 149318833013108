
// Centralized metadata configuration for different pages
const seoConfig = {
    home: {
      title: "Neon Desire - Unique ethical Adult Content, Anytime, Anywhere",
      description: "From videos and shorts to photos, get the hottest porn content with live updates on Neon Desire. Dive into an social media experience like no other."
    },
    videos: {
      title: "Neon Desire Videos - Stream the Latest Adult Content",
      description: "Watch exclusive, high-quality adult videos for free on Neon Desire, featuring the latest content from top creators"
    },
    shorts: {
      title: "Neon Desire Tik Tok Porn",
      description: "Discover Shorts – adult porn content in the TikTok style you love. engaging short videos updated frequently for endless enjoyment"
    },
    photos: {
      title: "Neon Desire Free Porn Photo Gallery",
      description: "Explore free porn photo gallery featuring high-quality adult images, updated frequently with fresh content"
    },
    profile: (username) => ({
      title: `${username} on Neon Desire`,
      description: `Explore ${username}'s exclusive adult content on Neon Desire. browse photos, videos, and more`
    }),
    search: (query) => ({
      title: `Search Results for "${query}" on Neon Desire`,
      description: `See the search results for "${query}" on Neon Desire.`
    }),
    about: {
      title: "About Neon Desire - Learn More About Our Platform",
      description: "The first platform to not allow ai content, an adult platform focused on ethics, security and innvation"
    },
    post: (username, description) => ({
      title: `${username} on Neon Desire: "${description}" `,
      description: `Check out "${description}" by ${username} on Neon Desire.`
    })
  };
  
  export default seoConfig;
