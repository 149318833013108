// src/components/Shorts.js
import React, { useEffect, useState, useCallback } from 'react';
import { fetchShortsFeed } from '../services/apiService'; // Import the fetchShortsFeed function
import './Shorts.css';
import LikeCommentShareButton from './LikeCommentShareButton'; // Import the LikeCommentShareButton component
import debounce from 'lodash.debounce';

// Import the SEO component and seoConfig
import SEO from './SEO'; // Adjust the path if SEO.js is in a different directory
import seoConfig from './seoConfig'; // Adjust the path based on your project structure

function Shorts() {
  const [shorts, setShorts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false); // Start with `false` since we don't want double-loading on the initial call.
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Function to fetch shorts from the API
  const loadShorts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      const data = await fetchShortsFeed(page);

      setShorts((prevShorts) => {
        // Filter out duplicate posts using unique post IDs
        const postIds = new Set(prevShorts.map((short) => short._id));
        const uniqueNewShorts = data.filter((short) => !postIds.has(short._id));
        return [...prevShorts, ...uniqueNewShorts];
      });

      setHasMore(data.length > 0);
      if (data.length > 0) {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching shorts:', error);
      setError('Failed to load shorts. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page]);

  useEffect(() => {
    loadShorts();
  }, [loadShorts]);

  // Debounced scroll handler to prevent excessive API calls
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 150 &&
        hasMore &&
        !loading
      ) {
        loadShorts();
      }
    }, 300);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadShorts, hasMore, loading]);

  return (
    <div className="shorts-container">
      {/* Add SEO component at the top */}
      <SEO title={seoConfig.shorts.title} description={seoConfig.shorts.description} />

      {loading && shorts.length === 0 && (
        <div className="shorts-container">Loading shorts...</div>
      )}

      {error && (
        <div className="shorts-container error-message">{error}</div>
      )}

      <h1 className="shorts-title">Shorts</h1>
      {shorts.length === 0 && !loading && !error ? (
        <p className="no-shorts-message">No shorts available at the moment.</p>
      ) : (
        <div className="shorts-grid">
          {shorts.map((short) => (
            <div className="short-item" key={short._id}>
              {short.mediaFiles && short.mediaFiles.length > 0 && (
                <video
                  src={short.mediaFiles[0]}
                  controls
                  title={short.description || 'Untitled'}
                  className="short-video"
                  preload="metadata"
                  poster={short.thumbnail || undefined}
                >
                  Your browser does not support the video tag.
                </video>
              )}
              <div className="short-info">
                <p className="short-caption">
                  {short.description || 'No description available.'}
                </p>
                <LikeCommentShareButton postId={short._id} />
              </div>
            </div>
          ))}
        </div>
      )}
      {loading && page > 1 && <div className="loading-more">Loading more shorts...</div>}
      {!hasMore && <div className="no-more-shorts">No more shorts to load.</div>}
    </div>
  );
}

export default Shorts;
