// src/components/Photos.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { fetchPhotosFeed } from '../services/apiService'; 
import './Photos.css';

import SEO from './SEO';
import seoConfig from './seoConfig';

import Ads from './Ads';

const Photos = () => {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const popUnderRef = useRef(null);

  const loadPosts = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    setError(null);

    try {
      const newPosts = await fetchPhotosFeed(page);

      setPosts((prevPosts) => {
        const existingPostIds = new Set(prevPosts.map((post) => post._id));
        const uniquePosts = newPosts.filter((post) => !existingPostIds.has(post._id));
        return [...prevPosts, ...uniquePosts];
      });

      if (newPosts.length === 0) {
        setHasMore(false);
      } else {
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching photos:', error);
      setError('Failed to load photos. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, page]);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 1 &&
        !loading
      ) {
        loadPosts();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadPosts, loading]);

  return (
    <div className="photos-container">
      <SEO 
        title={seoConfig.photos.title} 
        description={seoConfig.photos.description} 
      />

      {loading && page === 1 && (
        <p className="photos-container__loading">Loading photos...</p>
      )}

      {error && (
        <div className="photos-container__error-message">{error}</div>
      )}

      <h1 className="photos-title">Photo Gallery</h1>
      {posts.length === 0 && !loading && !error ? (
        <p className="no-photos-message">No photos available at the moment.</p>
      ) : (
        <div className="photos-grid">
          {posts.map((post, index) => (
            <React.Fragment key={post._id}>
              {/* Wrap the entire post item in a Link to enable middle/right click open in new tab */}
              <Link
                to={`/post/${post._id}`} 
                className="photo-item"
                aria-label={`View photo ${post.description || 'No description'}`}
                onClick={() => {
                  if (popUnderRef.current) {
                    popUnderRef.current.triggerPopUnder();
                  }
                }}
              >
                <img
                  src={post.mediaFiles[0]}
                  alt={post.description || 'Untitled post'}
                  className="photo-image"
                  loading="lazy"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/path/to/default-thumbnail.png';
                  }}
                />
                <div className="photo-details">
                  <p className="photo-title">
                    {post.description || 'Untitled'}
                  </p>
                  <p className="photo-views">
                    {post.viewCount !== undefined
                      ? `${post.viewCount} views`
                      : 'No views yet.'}
                  </p>
                </div>
              </Link>

              {(index + 1) % 9 === 0 && (
                <div className="ad-container" key={`ad-${index}`}>
                  <Ads
                    type="outstream"
                    spot="cd4c9882502440e7a0992e3ac3bcc30f"
                    containerId={`ts_ad_outstream_${index}`}
                    cookieExpires="4"
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      {loading && page > 1 && <div className="photos-container__loading-more">Loading more photos...</div>}
      {!hasMore && <div className="photos-container__no-more">No more photos to load.</div>}

      <Ads
        type="popUnder"
        spot="d2519a5ac9474befadc53a1a46876b50"
        ref={popUnderRef}
      />
    </div>
  );
};

export default Photos;
