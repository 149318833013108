import React from 'react';
import './TermsOfService.css'; // Import corresponding CSS for styling

function TermsOfService() {
  return (
    <div className="terms-container">
      <h2>Terms of Service for Neon Desire</h2>
      <p><strong>Effective Date:</strong> 10/31/2024</p>

      <h3>Introduction</h3>
      <p>
        Welcome to Neon Desire ("we," "us," "our"), an innovative content-sharing platform where users can connect, create, and engage with various types of content. By accessing or using Neon Desire, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service (ToS). If you do not agree, you may not access or use the platform.
      </p>

      <h3>Eligibility</h3>
      <p>
        To create an account and use Neon Desire, you must be at least 18 years of age or the legal age of majority in your jurisdiction. By accessing the platform, you confirm that you meet this requirement. We may take steps to verify user age and may suspend or terminate accounts if age misrepresentation is detected. It is your responsibility to comply with any local, state, or national laws applicable to your use of the platform.
      </p>

      <h3>Account Registration and Security</h3>
      <p>
        To access certain features of Neon Desire, you need to register for an account. You agree to provide accurate and up-to-date information during registration and maintain the confidentiality of your account credentials. You are responsible for all activities that occur under your account. If you suspect any unauthorized access or security breach, you must notify Neon Desire immediately at <a href="mailto:support@neondesire.com">support@neondesire.com</a>.
      </p>

      <h3>User Responsibilities</h3>
      <ul>
        <li>Upload content that you have the right to share and that complies with these ToS.</li>
        <li>Abide by all community guidelines and applicable laws.</li>
        <li>Avoid posting illegal, offensive, harassing, or abusive content.</li>
        <li>Use only the provided premade tags when categorizing posts to ensure consistency.</li>
        <li>Refrain from uploading AI-generated content.</li>
        <li>Comply with any tax obligations related to monetization earnings.</li>
      </ul>
      <p>Failure to comply with these requirements may result in content removal, suspension, or account termination.</p>

      <h3>Prohibited Conduct</h3>
      <ul>
        <li>Engaging in any activities that compromise the security or integrity of the platform, including spamming, impersonating others, reverse engineering, or unauthorized data scraping.</li>
        <li>Using the platform for unlawful or fraudulent purposes.</li>
        <li>Violating any applicable local, state, national, or international laws.</li>
      </ul>

      <h3>Content Ownership and Licensing</h3>
      <p>
        You retain ownership of any content you upload to Neon Desire. By sharing content, you grant Neon Desire a non-exclusive, royalty-free, worldwide, perpetual, and sublicensable license to use, reproduce, distribute, modify, and display your content for purposes related to the operation of the platform and promotional activities. You affirm that you have obtained all necessary rights and permissions for any content you post.
      </p>

      <h3>Monetization Features</h3>
      <p>
        Neon Desire offers features such as tipping and subscription services. By participating in these monetization options, you agree to the associated fees and terms set forth by Neon Desire. Refunds and chargebacks are not guaranteed and are subject to the platform's discretion. Users engaging in financial transactions must adhere to all applicable terms and policies and comply with relevant tax obligations.
      </p>

      <h3>Content Moderation and Compliance</h3>
      <p>
        Neon Desire reserves the right to review and moderate user content to ensure it complies with these ToS and community guidelines. Content that violates these terms may be removed, and repeat offenders may face account suspension or termination. Users can report content through the platform's flagging system. Neon Desire complies with all laws regarding adult content and requires users to do the same. Users whose content is flagged for review will be notified and may appeal content removal decisions within [X] days of notification.
      </p>

      <h3>DMCA Compliance</h3>
      <p>
        Neon Desire respects intellectual property rights and will respond to valid DMCA takedown requests. If you believe content on Neon Desire infringes your copyright, please submit a notice to <a href="mailto:support@neondesire.com">support@neondesire.com</a> including all required elements outlined in 17 U.S.C. § 512(c)(3).
      </p>

      <h3>Privacy and Data Use</h3>
      <p>
        Neon Desire collects user data as outlined in its Privacy Policy. By using the platform, you consent to data collection, storage, and processing as specified in the policy. Personal information, media files, and interaction data may be used to improve the platform's services and functionality.
      </p>

      <h3>Platform Rights and Intellectual Property</h3>
      <p>
        All platform-related intellectual property, including design elements, branding, and functionalities, belongs to Neon Desire. You agree not to reproduce, distribute, or create derivative works based on the platform’s intellectual property without written consent. Users grant Neon Desire the right to use uploaded content for promotional, marketing, and operational purposes as described in these ToS.
      </p>

      <h3>Limitations of Liability</h3>
      <p>
        To the maximum extent permitted by applicable law, Neon Desire shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use of the platform, including data loss, content unavailability, or errors. The platform and all services are provided "as is," without warranties of any kind, either express or implied. Neon Desire is not responsible for users' actions or the content posted by users on the platform.
      </p>

      <h3>Indemnification</h3>
      <p>
        You agree to indemnify, defend, and hold harmless Neon Desire and its affiliates, directors, officers, employees, and agents from any claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of your use of the platform, violation of these ToS, or infringement of any third-party rights.
      </p>

      <h3>Termination and Suspension</h3>
      <p>
        Neon Desire reserves the right to terminate or suspend your account at its sole discretion if you violate these ToS, engage in prohibited activities, or for any other reason deemed necessary. Termination of an account does not affect Neon Desire’s right to continue using previously licensed content. You may also choose to terminate your account at any time by contacting <a href="mailto:support@neondesire.com">support@neondesire.com</a>.
      </p>

      <h3>Dispute Resolution</h3>
      <p>
        Any disputes arising under these ToS shall be resolved through binding arbitration, following the rules of the American Arbitration Association and the laws of the United States. By agreeing to these ToS, you waive your right to participate in class action lawsuits and agree to pursue claims only on an individual basis. You also waive your right to a jury trial for any claims arising out of or related to your use of the platform.
      </p>

      <h3>Modifications to the Terms of Service</h3>
      <p>
        Neon Desire may modify these ToS at any time. Any changes will be communicated through the platform or via email. Your continued use of the platform following changes to the ToS constitutes acceptance of the updated terms.
      </p>

      <h3>Governing Law</h3>
      <p>
        These ToS shall be governed by and construed in accordance with the laws of the State of Texas, United States, without regard to its conflict of laws principles.
      </p>

      <h3>Severability</h3>
      <p>
        If any part of these ToS is found to be unenforceable, the rest will remain in full force and effect.
      </p>

      <h3>No Third-Party Rights</h3>
      <p>
        These ToS do not create any rights for third parties.
      </p>

      <h3>Contact Information</h3>
      <p>
        For questions or concerns regarding these ToS, please contact Neon Desire at <a href="mailto:support@neondesire.com">support@neondesire.com</a>.
      </p>
    </div>
  );
}

export default TermsOfService;
