// src/components/Videos.js
import React, { useEffect, useState, useCallback } from 'react';
import { fetchVideosFeed } from '../services/apiService';
import { useNavigate } from 'react-router-dom';
import './Videos.css';

import SEO from './SEO'; 
import seoConfig from './seoConfig'; 
import Ads from './Ads';

function Videos() {
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const navigate = useNavigate();

  const loadMoreVideos = useCallback(() => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [loading, hasMore]);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        setLoading(true);
        const data = await fetchVideosFeed(page);

        setVideos((prevVideos) => {
          const existingVideoIds = new Set(prevVideos.map((video) => video._id));
          const uniqueVideos = data.filter((video) => !existingVideoIds.has(video._id));
          return [...prevVideos, ...uniqueVideos];
        });

        setHasMore(data.length > 0);
      } catch (error) {
        console.error('Error fetching videos:', error);
        setError('Failed to load videos. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 100
      ) {
        loadMoreVideos();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loadMoreVideos]);

  const handleVideoClick = (videoId) => {
    navigate(`/post/${videoId}`);
  };

  return (
    <div className="video-feed">
      <SEO title={seoConfig.videos.title} description={seoConfig.videos.description} />

      {loading && page === 1 && (
        <p className="video-feed__loading">Loading videos...</p>
      )}

      {error && (
        <div className="video-feed__error-message">{error}</div>
      )}

      <h1 className="videos-title">Videos</h1>
      {videos.length === 0 && !loading && !error ? (
        <p className="video-feed__no-videos-message">No videos available at the moment.</p>
      ) : (
        <div className="videos-grid">
          {videos.map((video, index) => (
            <React.Fragment key={video._id}>
              <div
                className="video-item"
                onClick={() => handleVideoClick(video._id)}
                role="button"
                tabIndex={0}
                aria-label={`View video ${video.description || 'No description'}`}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleVideoClick(video._id);
                  }
                }}
              >
                {video.mediaTypes && video.mediaTypes.length > 0 && video.mediaTypes[0] === 'video' && (
                  <div className="video-thumbnail-container">
                    {video.thumbnails && video.thumbnails.length > 0 ? (
                      <img
                        src={video.thumbnails[0]}
                        alt={`Thumbnail for video ${video._id}`}
                        className="video-thumbnail"
                        loading="lazy"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = '/path/to/default-thumbnail.png';
                        }}
                      />
                    ) : (
                      // Show the first frame of the video as the fallback thumbnail
                      <video
                        src={video.mediaFiles[0]}
                        className="video-thumbnail"
                        preload="metadata"
                        muted
                        playsInline
                        onLoadedMetadata={(e) => {
                          // Seek to a small offset to force the first frame to display
                          e.target.currentTime = 0.1;
                          e.target.pause();
                        }}
                      />
                    )}
                  </div>
                )}
                <div className="video-details">
                  <p className="video-caption">
                    {video.description || 'No caption available.'}
                  </p>
                  <p className="video-views">
                    {video.viewCount !== undefined
                      ? `${video.viewCount} views`
                      : 'No views yet.'}
                  </p>
                </div>
              </div>

              {(index + 1) % 6 === 0 && (
                <div className="ad-container" key={`ad-${index}`}>
                  <Ads
                    type="outstream"
                    spot="cd4c9882502440e7a0992e3ac3bcc30f"
                    containerId={`ts_ad_video_${index}`}
                    cookieExpires="4"
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
      {loading && page > 1 && <div className="video-feed__loading-more">Loading more videos...</div>}
      {!hasMore && <div className="video-feed__no-more">No more videos to load.</div>}
    </div>
  );
}

export default Videos;
