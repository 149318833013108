// Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import { login } from '../services/apiService'; // Import the login function
import './Login.css'; // Assuming you want to reuse the same styling as SignUp

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Validate email format
  const validateEmail = (email) => {
    // Use a more comprehensive regex for email validation
    const emailRegex =
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission for login
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input validation
    if (!validateEmail(formData.email)) {
      setError('Invalid email format.');
      return;
    }
    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters.');
      return;
    }

    setError('');
    setLoading(true);

    // Sanitize the form data
    const sanitizedEmail = DOMPurify.sanitize(formData.email);
    const sanitizedPassword = DOMPurify.sanitize(formData.password);

    try {
      // Use the login function from apiService.js
      await login(sanitizedEmail, sanitizedPassword);

      setLoading(false);
      setError('');

      // Redirect to the home page after successful login
      navigate('/');
    } catch (err) {
      console.error('Error:', err.response?.data || err.message);
      setError(err.response?.data?.message || 'An error occurred during login.');
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2>Login</h2>
      <form onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
            autoComplete="email"
            disabled={loading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="password-input-wrapper">
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              required
              autoComplete="current-password"
              disabled={loading}
            />
            {/* Password visibility toggle could be added here */}
          </div>
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Logging In...' : 'Login'}
        </button>
      </form>
      {error && <p className="error-message">{error}</p>}

      <p className="sign-up-option">
        Don't have an account?{' '}
        <span onClick={() => navigate('/signup')} className="sign-up-link">
          Sign Up
        </span>
      </p>
    </div>
  );
}

export default Login;
