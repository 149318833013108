// src/components/Ads.js
import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

// Flags to ensure each script is loaded only once
let isBiScriptLoaded = false;
let isOutstreamScriptLoaded = false;
let isPopunderScriptLoaded = false;

const Ads = forwardRef(({
  type,
  spot,
  containerId,
  cookieExpires,
  width,
  height,
  extid,
}, ref) => {
  const adContainerRef = useRef(null);

  useEffect(() => {
    const loadScript = (src, id, attributes = {}) => {
      return new Promise((resolve, reject) => {
        if (document.getElementById(id)) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        Object.keys(attributes).forEach((key) => {
          script.setAttribute(key, attributes[key]);
        });
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        adContainerRef.current.appendChild(script);
      });
    };

    const handleAd = async () => {
      try {
        if (type === 'outstream') {
          if (!isOutstreamScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/outstream.video.js', 'ts-outstream-video-script');
            isOutstreamScriptLoaded = true;
          }
          if (window.TSOutstreamVideo) {
            window.TSOutstreamVideo({
              spot,
              containerId,
              cookieExpires,
            });
          }
        } else if (type === 'banner') {
          if (!isBiScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/bi.js', 'ts-banner-script', {
              'data-ts-spot': spot,
              'data-ts-width': width,
              'data-ts-height': height,
              'data-ts-extid': extid,
            });
            isBiScriptLoaded = true; // Ensure script is loaded only once
          }
          // The iframe is already rendered; no additional handling needed
        } else if (type === 'popUnder') {
          if (!isPopunderScriptLoaded) {
            await loadScript('//cdn.tsyndicate.com/sdk/v1/p.js', 'ts-popunder-script', {
              'data-ts-spot': spot,
            });
            isPopunderScriptLoaded = true;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    handleAd();

    return () => {
      if (type === 'outstream') {
        const container = document.getElementById(containerId);
        if (container) {
          container.innerHTML = '';
        }
      }
    };
  }, [type, spot, containerId, cookieExpires, width, height, extid]);

  // Expose the triggerPopUnder function to parent components
  useImperativeHandle(ref, () => ({
    triggerPopUnder: () => {
      if (type === 'popUnder') {
        const redirectUrl = `//tsyndicate.com/api/v1/direct/${spot}?`;
        const newWindow = window.open(redirectUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.focus();
        } else {
          console.warn('Pop-under was blocked by the browser.');
        }
      }
    },
  }));

  const renderAd = () => {
    if (type === 'outstream') {
      return <div id={containerId} ref={adContainerRef}></div>;
    } else if (type === 'banner') {
      return (
        <iframe
          width={width}
          height={height}
          frameBorder="0"
          scrolling="no"
          src={`//tsyndicate.com/iframes2/${spot}.html?extid=${extid}`}
          title="Banner Advertisement"
          ref={adContainerRef}
          loading="lazy"
        ></iframe>
      );
    }
    // No rendering needed for popUnder as it's triggered via method
    return null;
  };

  return <>{renderAd()}</>;
});

Ads.propTypes = {
  type: PropTypes.oneOf(['outstream', 'banner', 'popUnder']).isRequired,
  spot: PropTypes.string.isRequired,
  containerId: PropTypes.string, // Required for outstream ads
  cookieExpires: PropTypes.string, // Optional for outstream ads
  width: PropTypes.number, // Required for banner ads
  height: PropTypes.number, // Required for banner ads
  extid: PropTypes.string, // Required for banner ads
};

Ads.defaultProps = {
  cookieExpires: '4',
  width: 300,
  height: 250,
};

export default Ads;
