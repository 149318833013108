import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { signup } from '../services/apiService'; // Import the signup function
import './SignUp.css';

function SignUp() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Define allowed email domains
  const allowedDomains = new Set([
    'gmail.com',
    'yahoo.com',
    'outlook.com',
    'icloud.com',
    'protonmail.com',
    'zoho.com',
    'yandex.com',
    'gmx.com',
    'fastmail.com',
  ]);

  // Validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Validate email domain
  const validateEmailDomain = (email) => {
    const domain = email.split('@')[1]?.toLowerCase();
    return allowedDomains.has(domain);
  };

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission with JWT storage
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Input validation
    if (!validateEmail(formData.email)) {
      setError('Invalid email format.');
      return;
    }
    if (!validateEmailDomain(formData.email)) {
      setError(`Email domain '${formData.email.split('@')[1]}' is not allowed.`);
      return;
    }
    if (formData.password.length < 6) {
      setError('Password must be at least 6 characters.');
      return;
    }

    setError('');
    setLoading(true);

    // Sanitize the form data
    const sanitizedData = {
      username: DOMPurify.sanitize(formData.username),
      email: DOMPurify.sanitize(formData.email),
      password: DOMPurify.sanitize(formData.password),
    };

    try {
      // Use the signup function from the Axios service
      const data = await signup(
        sanitizedData.username,
        sanitizedData.email,
        sanitizedData.password
      );

      console.log('User registered:', data);

      setSuccess('Sign up successful!');
      setError('');
      setLoading(false);

      // Redirect to account page after sign-up
      navigate('/'); // Adjust the route as needed
    } catch (err) {
      console.error('Error during signup:', err);
      if (err.response && err.response.data) {
        if (err.response.data.errors && Array.isArray(err.response.data.errors)) {
          // There are validation errors
          const errorMessages = err.response.data.errors.map((error) => error.msg).join(', ');
          setError(errorMessages);
        } else if (err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError('An error occurred during sign up.');
        }
      } else {
        setError('An error occurred during sign up.');
      }
      setLoading(false);
    }
  };

  return (
    <div className="sign-up-container">
      <h2>Sign Up</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            name="username"
            id="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
      </form>
      {error && (
        <div className="error-message">
          {error.split(', ').map((errMsg, index) => (
            <p key={index}>{errMsg}</p>
          ))}
        </div>
      )}
      {success && <p className="success-message">{success}</p>}

      <p className="sign-in-option">
        Already have an account?{' '}
        <span onClick={() => navigate('/login')} className="sign-in-link">
          Sign In
        </span>
      </p>
    </div>
  );
}

export default SignUp;
