// UserPosts.js
import React from 'react';
import { Link } from 'react-router-dom'; // Added Link import
import './UserPosts.css';

const UserPosts = ({ userPosts }) => {
  return (
    <div className="user-posts-container">
      <div className="posts-grid">
        {userPosts.length > 0 ? (
          userPosts.map((post) => (
            <Link 
              key={post._id} 
              to={`/post/${post._id}`} 
              className="post-link"
              aria-label={`View post ${post.description || 'No description'}`}
            >
              <div className="post">
                <p className="post-description">{post.description}</p>
                {post.mediaFiles.length > 0 &&
                  post.mediaFiles.map((file, index) => (
                    <div key={index} className="media-container">
                      {post.mediaTypes[index] === 'image' ? (
                        <img src={file} alt="Post media" className="post-media" />
                      ) : post.mediaTypes[index] === 'video' ? (
                        <video controls className="post-media">
                          <source src={file} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : post.mediaTypes[index] === 'short' ? (
                        <video 
                          className="post-media short-video" 
                          autoPlay 
                          muted 
                          loop 
                          playsInline
                        >
                          <source src={file} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : null}
                    </div>
                  ))}
              </div>
            </Link>
          ))
        ) : (
          <p>No posts available</p>
        )}
      </div>
    </div>
  );
};

export default UserPosts;
